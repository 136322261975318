<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @keydown.esc="closeReadIMG"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon dark @click="closeReadIMG">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Edytowanie produktu: {{ productToEdit.name }} ({{
            productToEdit.ean
          }})</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark :loading="loading" color="green" @click="updateProduct">
            Aktualizuj
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container class="mt-16" fluid>
        <v-row justify="center">
          <v-col md="4">
            <v-file-input
              v-model="image"
              outlined
              accept="image/*"
              label="Klikni aby wczytać listę"
            ></v-file-input>
            <v-img
              v-if="productToEdit.img || url"
              class="align-end"
              width="100%"
              max-height="350px"
              :src="url"
              :lazy-src="url"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row> </template
            ></v-img>
          </v-col>
          <v-col md="3">
            <v-textarea
              outlined
              v-model="description"
              label="Opis produktu"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      image: null,
      description: "",
      selectedFile: null,
      loading: false,
    };
  },
  computed: {
    url() {
      if (!this.image)
        return `${this.apiLink}/api/uploads/product_img/${this.productToEdit.img}`;
      return URL.createObjectURL(this.image);
    },
  },
  watch: {
    productReadIMG(newVal) {
      this.dialog = newVal;
      this.description = this.productToEdit.description;
    },
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    productReadIMG: {
      type: Boolean,
      required: true,
    },
    productToEdit: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeReadIMG() {
      this.image = null;
      this.$emit("closeReadIMG");
    },
    onFileChange(e) {
      this.selectedFile = e.target.files[0];
      this.url = URL.createObjectURL(this.selectedFile);
    },
    updateProduct() {
      if (this.image) {
        this.uploadIMG();
      } else {
        this.updateProductIMG(this.productToEdit.img);
      }
    },
    async uploadIMG() {
      this.loading = true;
      const fd = new FormData();
      fd.append("image", this.image, this.image.name);

      await this.axios
        .post(`${this.apiLink}/api/views/products/uploadProductIMG.php`, fd, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.message == 1) {
              this.updateProductIMG(res.data.fileName);
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas wysyłania pliku. Bład w API: ${res.data}`,
                "error"
              );
            }
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas wysyłania pliku. Plik nie został zapisany. Błąd: ${err}`,
            "error"
          );
        });
    },
    async updateProductIMG(fileName) {
      let product = {
        id: this.productToEdit.id,
        description: this.description,
        fileName: fileName,
      };
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/products/updateProductIMG.php`,
        data: {
          token: localStorage.token,
          product,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.$emit("showAlert", "Zdjęcie zostało dodane.", "success");
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas zmiany zdjęcia. Zdjęcie nie zostało dodane. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.$emit("getProducts");
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas dodawania pliku. Plik nie został dodany. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas dodawania pliku. Plik nie został dodany. Błąd: ${err}`,
            "error"
          );
        });
      this.closeReadIMG();
      this.loading = false;
    },
  },
};
</script>

<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>