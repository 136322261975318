<template>
  <section class="cms-products">
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <Alert
        :alertShow="alertShow"
        :alertText="alertText"
        :alertType="alertType"
        @closeAlert="closeAlert"
      />
      <v-container fluid>
        <v-row>
          <v-col md="10"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                >Ilość produktów:
                <span v-if="products" class="green--text">{{
                  products.length
                }}</span>
                <span v-else
                  ><v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  ></v-progress-circular></span></v-card-text></v-card
          ></v-col>
          <v-col md="2"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                ><v-btn @click="showReadList" color="green" block dark
                  >Wczytaj listę
                  <v-icon class="ml-2">mdi-archive-plus</v-icon></v-btn
                >
              </v-card-text></v-card
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12"
            ><ProductsTable
              :productTableLoading="productTableLoading"
              :products="products"
              :productReadIMG="productReadIMG"
              @showEditProduct="showEditProduct"
              @showReadIMG="showReadIMG"
              @toogleProductActive="toogleProductActive"
          /></v-col>
        </v-row>
      </v-container>
    </div>
    <ProductReadList
      :productReadListView="productReadListView"
      :apiLink="apiLink"
      @showAlert="showAlert"
      @getProducts="getProducts"
      @closeReadListView="closeReadListView"
    />
    <ProductReadIMG
      :productReadIMG="productReadIMG"
      :apiLink="apiLink"
      :productToEdit="productToEdit"
      @showAlert="showAlert"
      @getProducts="getProducts"
      @closeReadIMG="closeReadIMG"
    />
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Alert from "@/components/Alert.vue";
import ProductsTable from "@/components/CMS/Products/ProductsTable.vue";
import ProductReadList from "@/components/CMS/Products/ProductReadList.vue";
import ProductReadIMG from "@/components/CMS/Products/ProductReadIMG.vue";

export default {
  name: "CMSProducts",
  data() {
    return {
      products: [],
      productTableLoading: false,
      productToEdit: {},
      alertText: "",
      alertType: "success",
      alertShow: false,
      productReadListView: false,
      productEditView: false,
      productReadIMG: false,
    };
  },
  components: {
    CMSNavTop,
    CMSNavSidebar,
    Alert,
    ProductsTable,
    ProductReadList,
    ProductReadIMG,
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    showReadList() {
      this.productReadListView = true;
    },
    showEditProduct() {
      this.productEditView = true;
    },
    showReadIMG(product) {
      this.productReadIMG = true;
      this.productToEdit = product;
      console.log(product);
    },
    closeReadIMG() {
      this.productReadIMG = false;
    },
    closeReadListView() {
      this.productReadListView = false;
    },
    closeEditProductView() {
      this.productEditView = false;
    },
    showAlert(alertText, alertType) {
      this.alertShow = true;
      this.alertText = alertText;
      this.alertType = alertType;
      console.log(alertText);
    },
    closeAlert() {
      this.alertShow = false;
    },
    toogleProductActive(product) {
      this.productTableLoading = true;
      this.updateProduct(product);
      this.productTableLoading = false;
    },
    async getProducts() {
      this.productTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/products/getProducts.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.products = res.data;
          } else {
            this.showAlert(
              `Błąd podczas pobierania produktów. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania produktów. Błąd: ${err}`, "error");
        });
      this.productTableLoading = false;
    },
    async updateProduct(product) {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/products/updateProduct.php`,
        data: {
          token: localStorage.token,
          product,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.showAlert("Zmiany zostały zapisane", "success");
            } else {
              this.showAlert(
                `Błąd podczas edytowania firmy. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.getProducts();
          }
        })
        .catch((err) => {
          this.showAlert(
            `Błąd podczas edytowania firmy. Zmiany nie zostały zapisane. Błąd: ${err}`,
            "error"
          );
        });
    },
  },
};
</script>