<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card :loading="loading">
      <v-toolbar class="text-h5 mb-10">Pobieranie listy produktów</v-toolbar>
      <v-card-text class="mb-0 pb-0">
        <v-file-input
          v-model="listFile"
          outlined
          accept=".csv, .txt"
          label="Klikni aby wczytać listę"
        ></v-file-input>
        <v-progress-linear
          v-if="loading"
          striped
          :value="loadingStatus"
          height="20"
        >
          {{ loadingStatus }}%
        </v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col md="6">
              <v-btn
                block
                :loading="loading"
                color="red darken-1"
                @click="closeReadListView"
              >
                Wyjdz
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn
                block
                :loading="loading"
                color="green darken-1"
                @click="uploadList"
              >
                Zaczytaj
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      listFile: null,
      loadingStatus: 0,
    };
  },
  watch: {
    productReadListView(newVal) {
      this.dialog = newVal;
    },
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    productReadListView: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeReadListView() {
      this.$emit("closeReadListView");
    },
    async uploadList() {
      this.loading = true;
      this.loadingStatus = 0;
      // const fd = new FormData();
      // fd.append("file", this.listFile, this.listFile.name);

      await this.axios({
        method: "POST",
        url: `${this.apiLink}/api/views/products/setAllUnActive.php`,
        data: {
          token: localStorage.token,
        },
      });

      let reader = new FileReader();
      reader.onload = async () => {
        let listLines = reader.result.split("\r\n");
        listLines.shift();
        listLines.shift();
        const splitPartsLength = 1000;

        const partsCount = Math.ceil(listLines.length / splitPartsLength);

        for (let i = 0; i < partsCount; i++) {
          const el = listLines
            .slice(
              i * splitPartsLength,
              i * splitPartsLength + splitPartsLength
            )
            .join("\r\n");
          const currentPart = i + 1;
          await this.sendListPartData(el);
          this.loadingStatus = Math.ceil((currentPart / partsCount) * 100);
        }

        this.closeReadListView();
        this.$emit("getProducts");
        this.loading = false;
      };
      reader.readAsText(this.listFile, "CP1250");
    },
    async sendListPartData(part) {
      this.loading = true;
      let res = await this.axios({
        method: "POST",
        url: `${this.apiLink}/api/views/products/uploadPartList.php`,
        data: {
          token: localStorage.token,
          part: part,
        },
      });
      console.log(res);
      this.loading = false;
    },
    // async sendListPartData(fd) {
    //   await this.axios
    //     .post(`${this.apiLink}/api/views/products/uploadProductsList.php`, fd, {
    //       header: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         if (res.data == 1) {
    //           this.$emit("showAlert", "Lista została wczytana.", "success");
    //         } else {
    //           this.$emit(
    //             "showAlert",
    //             `Błąd podczas wysyłania listy. Bład w API: ${res.data}`,
    //             "error"
    //           );
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       this.$emit(
    //         "showAlert",
    //         `Błąd podczas wysyłania listy. Plik nie został zapisany. Błąd: ${err}`,
    //         "error"
    //       );
    //     });
    // },
  },
};
</script>
