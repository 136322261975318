<template>
  <section class="cms-product-table">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Szukaj"
      single-line
      hide-details
      clearable
      class="mb-3 elevation-1 pa-3"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="products"
      :search="search"
      :header-props="headerProps"
      :loading="productTableLoading"
      loading-text="Wczytywanie... Proszę czekać"
      :footer-props="{
        'items-per-page-text': 'Produktów na stronę',
        'items-per-page-options': [10, 25, 50, 100],
      }"
      :items-per-page="10"
      :sort-by="['active', 'name']"
      :sort-desc="true"
      class="elevation-1"
      multi-sort
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.ean }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.vat_percent }}</td>
          <td>{{ item.producer_name }}</td>
          <td>{{ item.category_name }}</td>
          <td>{{ item.weight }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.in_package }}</td>
          <td>{{ item.datetime_created }}</td>
          <td width="110px" :class="item.active ? 'green--text' : 'red--text'">
            {{ item.active ? "Tak" : "Nie" }}
          </td>
          <td width="110px" :class="item.img ? 'green--text' : 'red--text'">
            {{ item.img ? "Tak" : "Nie" }}
          </td>
          <td width="110px" :class="item.is_new ? 'green--text' : 'red--text'">
            {{ item.is_new ? "Tak" : "Nie" }}
          </td>
          <td
            width="110px"
            :class="item.description ? 'green--text' : 'red--text'"
          >
            {{ item.description ? "Tak" : "Nie" }}
          </td>
          <td width="10px">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      dark
                      color="warning"
                      @click.prevent="showReadIMG(item)"
                      >Edytuj</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      dark
                      color="red"
                      @click.prevent="toogleProductActive(item)"
                      >{{ item.active ? "Archiwizuj" : "Przywróć" }}</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: "ProductTable",
  data() {
    return {
      search: "",
      productEditView: false,
      headers: [
        { text: "Nazwa", value: "name" },
        { text: "EAN", value: "ean" },
        { text: "Cena", value: "price" },
        { text: "Vat (%)", value: "vat_percent" },
        { text: "Producent", value: "producer_name" },
        { text: "Kategoria", value: "category_name" },
        { text: "Waga", value: "weight" },
        { text: "JM", value: "unit" },
        { text: "W opakowaniu", value: "in_package" },
        { text: "Data dodania", value: "datetime_created" },
        { text: "Aktywność", value: "active" },
        { text: "Zdjęcie", value: "img" },
        { text: "Nowość", value: "is_new" },
        { text: "Opis", value: "description" },
        { text: "Akcje", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortowanie",
      },
    };
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    productTableLoading: {
      type: Boolean,
      required: true,
    },
  },
  created() {},
  methods: {
    showReadIMG(product) {
      this.$emit("showReadIMG", product);
    },
    toogleProductActive(product) {
      product.active = !product.active;
      this.$emit("toogleProductActive", product);
    },
  },
};
</script>

